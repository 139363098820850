import React from 'react'
import Layout from '../components/layout'

const Documentation = () => (
  <Layout>
    <h1>Docs</h1>
  </Layout>
)

export default Documentation
